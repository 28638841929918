import { MDBContainer, MDBRow, MDBCol, MDBIcon, MDBTooltip } from 'mdb-react-ui-kit';
import React, { Component } from 'react'; //, { useState }
import { withRouter } from 'react-router-dom';
import './CompensationTab.scss';
import * as Constants from "../../../../constants/constants";
import CasterService from "../../../../services/service";
import SelectField from '../../SharedComponents/SelectField/SelectField';
import DateField from '../../SharedComponents/DateField/DateField';
import BasicTextField from '../../SharedComponents/BasicTextField/BasicTextField';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Close';
import { contractConfig } from './Config';
import { formatRate } from '../../../Common/Helper';
import MessageModal from '../../SharedComponents/MessageModal';
import BasicTextArea from '../../SharedComponents/BasicTextArea/BasicTextArea';
import { Popover } from '@material-ui/core';
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import Modal from '../../../Common/MainViewModalLayout/Modal/Modal';
import ModalWithBackdrop from '../../../Common/MainViewModalLayout/Modal/ModalWithBackdrop';
import BasicCheckbox from '../../SharedComponents/BasicCheckbox/BasicCheckbox';
import BasicLabel from '../../SharedComponents/BasicLabel/BasicLabel';
import { CircularProgress } from "@material-ui/core";
import { getFormattedDate, downloadUrl, extractFilenameFromUrl } from '../../../Common/Helper';
import Radio from "@material-ui/core/Radio";
import Tooltip from '@material-ui/core/Tooltip';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RichTextEditor from '../../SharedComponents/RichTextEditor/RichTextEditor';
import ReactHtmlParser from 'react-html-parser';

let selectedContract = null, fileFormData = null, globalIndex = null;
export default class CompensationTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            performerTypeOptions: [],
            feeOptions: [],
            selectedFeeType: null,
            selectedPerformerType: null,
            newContractTemplate: null,
            showCancelContractModal: false,
            newOrGlobal: null,
            currencyOptions: [],
            guaranteePeriodOptions: [],
            deletedContracts: [],
            periodOptions: [],
            dealContracts: [],
            postDealContract: [],
            isEdit: false,
            postingContract: false,
            newContract: JSON.parse(JSON.stringify({ ...contractConfig })),
            backupNewContract: JSON.parse(JSON.stringify({ ...contractConfig })),
            //showDelete: false,
            openInfoPopover: false,
            openFileContractPopover: false,
            confimationMessage: null,
            index: null,
            openEsignModal: false,
            openEditRider: false,
            performer_deal_contract_id: null,
            contract_info: null,
            loadingContracts: false,
            tempDealContracts: null,
            initialDealContracts: [],
            backupDealContracts: [],
            openNewInfoPopover: false,
            openNewContractPopover: false,
            rider_info: [],
            rider_type: null,
            openPopover: false,
            fileName: null,
            fileType: null,
            file: null,
            upload_as: "Unsigned",
            file_to_upload: "system-generated",
            qualfier_options: [],
            uploadingFileToBox: false,
            file_name: null,
            downloadingPDF: false,
            downloadingParentalAgreements: false,
            deletingContract: false,
            principalPeriodOptions: [],
            basicFreeOptions: [],
            postFreeOptions: [],
            plusMinusOptions: [],
            pdfDownloadFailed: false,
            pdfFailMssg: null,
            postInitiated: false,
            postInitiatedNew: false,
            tooltipBtnPressed: null,
            showSaveCancelButtons: false,
            disableGlobalSave: false
        }
    }

    componentDidMount = () => {
        this.getCompensationTabData();
        this.getSearchValues("PERFORMER_TYPE", this.props?.contract_type);
        this.getLookUpValues("CURRENCY");
        this.getLookUpValues("GUARANTEE_PERIOD");
        this.getLookUpValues("PRINCIPAL_FREE_TYPE");
        this.getLookUpValues("BASIC_FREE_TYPE");
        this.getLookUpValues("POST_FREE_TYPE");
        this.getLookUpValues("PLUS_OR_MINUS_TYPE");
        this.getLookUpValues("PERIOD");
        this.getLookUpValues("START_DATE_QUALIFIERS");
    }

    getCompensationTabData = () => {
        this.setState({ loadingContracts: true })
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeaturePerformerDealContract?performer_deal_id=${this.props?.performer_deal_id}`)
            .then(response => {
                if (!response || response?.data?.error) {
                    this.setState({ loadingContracts: false })
                    console.log("error", response?.data?.error)
                }
                else {
                    let formattedList = response?.data
                    formattedList.isEdit = 0;
                    formattedList.postInitiatedRate = false;
                    formattedList.postInitiatedGuarentee = false;
                    this.setState({ dealContracts: JSON.parse(JSON.stringify([...formattedList])), loadingContracts: false, initialDealContracts: JSON.parse(JSON.stringify([...formattedList])), backupDealContracts: JSON.parse(JSON.stringify([...formattedList])) })
                    console.log("Data:", response?.data)
                }
            }, err => {
                this.setState({ loadingContracts: false })
                console.log("error from lookup")
            })
    }

    getSearchValues = (objectType, searchString = "") => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureSearch?objectType=${objectType}&searchString=${searchString}`)
            .then(response => {
                if (!response || response?.data?.error) {
                    console.log("error", response?.data?.error)
                }
                else {
                    if (objectType === "PERFORMER_TYPE") {
                        let formattedList = response?.data?.map((item) => ({ label: item.performer_type, value: item.performer_type, contract_type: item?.contract_type }))
                        this.setState({ performerTypeOptions: formattedList })
                    }
                    else if (objectType === "FEE_TYPE") {
                        let formattedList = response?.data?.map((item) => ({ label: item.fee_type, value: item.fee_type }))
                        this.setState({ feeOptions: formattedList })
                    } else if (objectType === "CONTRACT_CONFIG") {
                        let guarentee_period_type = null;
                        if (response.data[0]?.fee_type === "Schedule F" || response.data[0]?.fee_type === "Schedule F - Daily" || response.data[0]?.fee_type === "Schedule F - Weekly") {
                            guarentee_period_type = null;
                        }
                        else {
                            guarentee_period_type = response.data[0]?.as_value === "Daily" ? "Days" : response.data[0]?.as_value === "Weekly" ? "Weeks" : null;
                        }
                        let newContract = JSON.parse(JSON.stringify({ ...contractConfig }))
                        newContract.guarentee_period_type = guarentee_period_type
                        newContract.currency_code = this.props?.default_currency ? this.state.currencyOptions?.find(item => item?.currencySymbol === this.props?.default_currency)?.label?.split(" ")?.[0] : "$"
                        newContract.currency = this.props?.default_currency_value ? this.props?.default_currency_value : this.state.currencyOptions?.find(item => item?.currencySymbol === this.props?.default_currency_value)?.label?.split(" ")?.[1]
                        newContract.post_free_type = guarentee_period_type
                        newContract.principal_free_type = guarentee_period_type
                        newContract.basic_free_type = guarentee_period_type
                        newContract.plus_or_minus_type = guarentee_period_type
                        newContract.deal_date = this.props?.deal_date
                        newContract.rate = response.data[0].rate_amount
                        this.setState({ newContractTemplate: response.data[0], newContract: newContract, contractConfigLoading: false })
                    }
                }
            }, err => {
                console.log("error from lookup")
            })
    }

    getLookUpValues = (type) => {
        CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureLookup?objectType=${type}`)
            .then(response => {
                if (!response || response?.data?.error) {
                    console.log("error", response?.data?.error)
                }
                else {
                    let formattedList = type != "CURRENCY" ? response?.data?.map(item => ({ id: item?.lookup_data_id, value: item?.lookup_value, label: item?.lookup_value })) :
                        response?.data?.map(item => ({ id: item?.lookup_data_id, value: (item?.lookup_value === "$" ? item?.lookup_code : item?.lookup_code + ' ') + (item?.lookup_value === "$" ? "" : item?.lookup_value), label: (item?.lookup_value === "$" ? item?.lookup_code : item?.lookup_code + ' ') + (item?.lookup_value === "$" ? "" : item?.lookup_value), currencySymbol: item?.lookup_code, currencyValue: item?.lookup_value }))
                    if (type == "CURRENCY") {
                        this.setState({ currencyOptions: formattedList });
                    } else if (type === "GUARANTEE_PERIOD") {
                        this.setState({ guaranteePeriodOptions: formattedList });
                    } else if (type === "PRINCIPAL_FREE_TYPE") {
                        this.setState({ principalPeriodOptions: formattedList });
                    } else if (type === "BASIC_FREE_TYPE") {
                        this.setState({ basicFreeOptions: formattedList });
                    } else if (type === "POST_FREE_TYPE") {
                        this.setState({ postFreeOptions: formattedList });
                    } else if (type === "PLUS_OR_MINUS_TYPE") {
                        this.setState({ plusMinusOptions: formattedList })
                    } else if (type === "PERIOD") {
                        let rehearsal = formattedList?.filter(item => item?.value === "Rehearsal")
                        let periodOptions = []
                        formattedList?.map((item) => {
                            if (item.value != "Rehearsal") {
                                periodOptions.push(item)
                            }
                        })
                        periodOptions?.push(rehearsal[0])
                        this.setState({ periodOptions: periodOptions });
                    } else if (type === "START_DATE_QUALIFIERS") {
                        this.setState({ qualfier_options: formattedList })
                    }
                }
            }, err => {
                console.log("error from lookup")
            })
    }

    updateGlobalSaveDisability = (contractArray = null) => {
        let disableGlobalSave = this.state.disableGlobalSave;
        let openEditContracts = [];
        if (contractArray !== null) {
            openEditContracts = contractArray?.filter(i => i?.isEdit === true || i?.isEdit === 1);
        } else {
            openEditContracts = this.state.dealContracts?.filter(i => i?.isEdit === true || i?.isEdit === 1);
        }
        console.log("openEditContracts", openEditContracts);
        if (openEditContracts.length > 0) {
            disableGlobalSave = true;
        } else {
            disableGlobalSave = false;
        }
        this.setState({ disableGlobalSave });
    }

    editContract = (index) => {
        globalIndex = index
        let dealContracts = this.state.dealContracts;
        dealContracts[index].isEdit = !dealContracts[index].isEdit;
        this.setState({ dealContracts: dealContracts }, this.updateGlobalSaveDisability())
    }

    onContractChange = (index, field, value) => {
        globalIndex = index;
        let dealContracts = JSON.parse(JSON.stringify([...this.state.dealContracts]));
        if (field === "descript") {
            dealContracts[index][field] = value === true ? 1 : 0
        } else if (field === "currency") {
            dealContracts[index]["currency_code"] = value?.split(" ")?.[0]
            dealContracts[index][field] = value?.split(" ")?.[1]
        } else {
            if (field === "rate") {
                dealContracts[index][field] = value;
                if (value.trim().length > 0) {
                    dealContracts[index]["postInitiatedRate"] = false;
                } else {
                    dealContracts[index]["postInitiatedRate"] = true;
                }
            } else if (field === "guarentee_period") {
                dealContracts[index][field] = value;
                if (value.trim().length > 0) {
                    dealContracts[index]["postInitiatedGuarentee"] = false;
                } else {
                    dealContracts[index]["postInitiatedGuarentee"] = true;
                }
            } else {
                if (value === "") {
                    dealContracts[index][field] = null;
                } else {
                    dealContracts[index][field] = value;
                }
            }

        }
        this.setState({ dealContracts })
    }
    handleOnsaveRTE = (dealContractsUpdated, identifier) => {
        console.log("inside handleOnsaveRTE", dealContractsUpdated);
        console.log("identifier", identifier);
        if (identifier === "info" || identifier === "text") {
            this.setState({ dealContracts: dealContractsUpdated });
            if (identifier === "info") {
                this.hideInfoPopover();
            } else if (identifier === "text") {
                this.hideFileContractPopover();
            }
        }
        if (identifier === "newInfo") {
            this.onNewContractPopOverChange("info", dealContractsUpdated?.info);

            //this.setState({ backupNewContract: dealContractsUpdated });
            this.hideNewInfoPopover();
        }
        if (identifier === "newText") {
            this.onNewContractPopOverChange("text", dealContractsUpdated?.text);
            //this.setState({ backupNewContract: dealContractsUpdated });
            this.hideNewFileContractPopover();
        }
    }
    onNewContractChange = (field, value) => {
        console.log("value", value);
        if (field === "descript") {
            if (value) {
                this.setState((prevState) => ({
                    newContract: {
                        ...prevState?.newContract,
                        [field]: 1
                    }
                }))
            } else {
                this.setState((prevState) => ({
                    newContract: {
                        ...prevState?.newContract,
                        [field]: 0
                    },
                    postInitiated: false
                }))
            }
        } else if (field === "currency") {
            let newContract = this.state.newContract
            newContract["currency_code"] = value?.split(" ")?.[0]
            newContract[field] = value?.split(" ")?.[1]
            this.setState({ newContract: newContract })
        } else {
            if (field === "rate") {
                if (value.trim().length > 0) {
                    this.setState((prevState) => ({
                        newContract: {
                            ...prevState?.newContract,
                            [field]: value,
                            ["postInitiatedRateNew"]: false
                        }
                    }))
                } else {
                    this.setState((prevState) => ({
                        newContract: {
                            ...prevState?.newContract,
                            [field]: value,
                            ["postInitiatedRateNew"]: true
                        }
                    }))
                }
            } else if (field === "guarentee_period") {

                if (value.trim().length > 0) {
                    this.setState((prevState) => ({
                        newContract: {
                            ...prevState?.newContract,
                            [field]: value,
                            ["postInitiatedGuarenteeNew"]: false
                        }
                    }))

                } else {
                    this.setState((prevState) => ({
                        newContract: {
                            ...prevState?.newContract,
                            [field]: value,
                            ["postInitiatedGuarenteeNew"]: true
                        }
                    }))
                }
            } else {
                this.setState((prevState) => ({
                    newContract: {
                        ...prevState?.newContract,
                        [field]: value
                    }
                }))
            }

        }


    }
    convertContractValues = (arr) => {
        for (var i = 0; i < arr?.length; i++) {
            //arr[i].isEdit = 0;
            arr[i].contract_date = arr[i].deal_date;

            arr[i].total_amount = arr[i].rate != "Schedule F - Daily" && arr[i].rate != "Schedule F - Weekly" ? arr[i].rate * arr[i].guarentee_period : arr[i].rate;

            arr[i].total_amount = arr[i].total_amount?.toString();

            arr[i].plus_or_minus_type = arr[i].plus_or_minus_period != null && arr[i].plus_or_minus_type != null ? arr[i].plus_or_minus_type : (arr[i].plus_or_minus_period != null && arr[i].plus_or_minus_type === null ? arr[i].guarentee_period_type : null);

            arr[i].principal_free_type = arr[i].principal_free_period != null && arr[i].principal_free_type != null ? arr[i].principal_free_type : (arr[i].principal_free_period != null && arr[i].principal_free_type === null ? arr[i].guarentee_period_type : null);

            arr[i].post_free_type = arr[i].post_free_period != null && arr[i].post_free_type != null ? arr[i].post_free_type : (arr[i].post_free_period != null && arr[i].post_free_type === null ? arr[i].guarentee_period_type : null);

            arr[i].basic_free_type = arr[i].basic_free_period != null && arr[i].basic_free_type != null ? arr[i].basic_free_type : (arr[i].basic_free_period != null && arr[i].basic_free_type === null ? arr[i].guarentee_period_type : null);

            arr[i].currency = arr[i].currency === undefined ? "$" : arr[i].currency;

            arr[i].rate = arr[i]?.rate?.toString();

        }
    }
    saveContractTemp = () => {
        let dealContracts = JSON.parse(JSON.stringify([...this.state.dealContracts]));
        let postDealContract = JSON.parse(JSON.stringify([...this.state.dealContracts]));
        let deletedContracts = JSON.parse(JSON.stringify([...this.state.deletedContracts]))
        if (this.state.deletedContracts.length > 0) {
            postDealContract = postDealContract.concat(deletedContracts);
        }
        globalIndex = null;
        this.setState({ selectedPerformerType: null, selectedFeeType: null, postInitiated: false });
        this.convertContractValues(postDealContract);
        this.props?.updateDiscardChangesFlag(true);
        this.props?.updateDiscardChangesFlagParent(true);
        this.setState({ showSaveCancelButtons: true, postDealContract, dealContracts }, this.updateGlobalSaveDisability());
    }
    validateBeforeSave = (index) => {
        globalIndex = index;
        let dealContract = this.state.dealContracts[index];
        console.log("dealContract in validateBeforeSave", dealContract);
        console.log("1st condition", dealContract?.fee_type === "Schedule F");
        console.log("2nd condition", ((dealContract?.show_guarantee === 0 && dealContract?.guarentee_period === null) || (dealContract?.show_guarantee === 1 && dealContract?.guarentee_period !== null) || (dealContract?.show_guarantee === 1 && dealContract?.guarentee_period !== "" && dealContract?.guarentee_period !== null)));
        console.log("3rd condition", ((dealContract?.show_rate === 1 && dealContract?.rate !== null) || (dealContract?.show_rate === 1 && dealContract?.rate !== "" && dealContract?.rate !== null) || (dealContract?.show_rate === 0 && dealContract?.rate === null)));

        if ((dealContract?.fee_type === "Schedule F") || (((dealContract?.show_guarantee === 0 && dealContract?.guarentee_period === null) || (dealContract?.show_guarantee === 1 && dealContract?.guarentee_period !== "" && dealContract?.guarentee_period !== null)) && ((dealContract?.show_rate === 1 && dealContract?.rate !== "" && dealContract?.rate !== null) || (dealContract?.show_rate === 0 && dealContract?.rate === null)))) {
            let tempBackupDealContracts = JSON.parse(JSON.stringify([...this.state.backupDealContracts]));
            //this.saveContract(index);
            dealContract.isEdit = !dealContract?.isEdit;
            dealContract.rate = Number(dealContract?.rate);
            dealContract.rate = dealContract?.rate?.toFixed(2);;
            tempBackupDealContracts[index] = JSON.parse(JSON.stringify({ ...this.state.dealContracts[index] }));
            console.log("tempBackupDealContracts in validateBeforeSave", tempBackupDealContracts[index]);
            this.setState({ backupDealContracts: tempBackupDealContracts }, this.saveContractTemp());
        }
    }

    saveContract = () => {

        let tempPostArray = JSON.parse(JSON.stringify([...this.state.postDealContract]));
        let unsavedEditItems = [];
        for (let i = 0; i < tempPostArray.length; i++) {
            if (tempPostArray[i].performer_deal_contract_id === null && tempPostArray[i].isEdit) {
                tempPostArray.splice(tempPostArray[i], 1);
            }
            if (tempPostArray[i].performer_deal_contract_id !== null && tempPostArray[i].isEdit) {
                unsavedEditItems.push(tempPostArray[i]);
            }
        }

        let originalValuesOfUnsavedItems = unsavedEditItems.map(obj => this.state.initialDealContracts.find(o => o.performer_deal_contract_id === obj.performer_deal_contract_id) || obj);


        this.convertContractValues(originalValuesOfUnsavedItems);
        let finalPostArr = tempPostArray.map(obj => originalValuesOfUnsavedItems.find(o => o.performer_deal_contract_id === obj.performer_deal_contract_id) || obj);

        let postObj = { "performer_deal_contract": JSON.parse(JSON.stringify([...finalPostArr])) }
        this.setState({ postingContract: true })
        CasterService.postDataParams(Constants.CasterServiceBaseUrl + `/casterFeaturePerformerDealContract`, postObj, this.props.userContext?.active_tenant?.tenant_id)
            .then(response => {
                if (!response || response?.data?.error) {
                    this.setState({ postingContract: false, postInitiated: false })
                    this.props?.notificationComponent(true, "fail");
                    console.log("error", response?.data?.error)
                }
                else {
                    this.setState({ postingContract: false, selectedPerformerType: null, selectedFeeType: null })
                    if (this.state.deletingContract) {
                        this.setState({ deletingContract: false }, () => {
                            this.props?.notificationComponent(true, "success");
                        });
                    }
                    else {
                        this.setState({ newContract: contractConfig, postInitiated: false }, () => {
                            this.props?.notificationComponent(true, "success");
                        })
                    }
                    this.getCompensationTabData();
                    // this.props?.getTalentPerformerDetails()
                    console.log("Post Successful:", response?.data);
                    this.setState({ showSaveCancelButtons: false });
                    this.props?.updateDiscardChangesFlag(false);
                    this.props?.updateDiscardChangesFlagParent(false);
                }
            }, err => {
                this.setState({ postingContract: false, postInitiated: false })
                this.props?.notificationComponent(true, "fail");
                console.log("error from lookup")
            })
    }
    addNewContract = () => {
        let dealContracts = JSON.parse(JSON.stringify([...this.state.dealContracts]));
        let newContract = JSON.parse(JSON.stringify({ ...this.state.newContract }));
        let tempBackupDealContracts = JSON.parse(JSON.stringify([...this.state.backupDealContracts]));
        //let contractsLength = dealContracts.length;
        newContract.performer_deal_id = this.props?.performer_deal_id
        newContract.performer_deal_contract_id = null;
        newContract.isEdit = 0;
        newContract.contract_type = this.props?.contract_type
        newContract.performer_type = this.state.selectedPerformerType
        newContract.fee_type = this.state.selectedFeeType
        newContract.contract_status = "Not Sent"
        newContract.plus_or_minus_type = newContract.plus_or_minus_period != null ? newContract.plus_or_minus_type : null
        newContract.principal_free_type = newContract.principal_free_period != null ? newContract.principal_free_type : null
        newContract.post_free_type = newContract.post_free_period != null ? newContract.post_free_type : null
        newContract.basic_free_type = newContract.basic_free_period != null ? newContract.basic_free_type : null
        newContract.rate = newContract.rate != null ? Number(newContract.rate) : null
        newContract.show_rate = this.state.newContractTemplate?.rate;
        newContract.show_deal_date = this.state.newContractTemplate?.deal_date;
        newContract.show_guarantee = this.state.newContractTemplate?.guarantee
        newContract.show_period = this.state.newContractTemplate?.period
        newContract.show_start_date = this.state.newContractTemplate?.start_date
        newContract.show_plus_or_minus_group = this.state.newContractTemplate?.plus_or_minus_group
        newContract.show_principal_free_group = this.state.newContractTemplate?.principal_free_group
        newContract.show_post_free_group = this.state.newContractTemplate?.post_free_group
        newContract.show_basic_free_group = this.state.newContractTemplate?.basic_free_group
        newContract.show_sent_date = this.state.newContractTemplate?.sent_date
        newContract.show_returned_date = this.state.newContractTemplate?.returned_date
        newContract.show_revised_date = this.state.newContractTemplate?.revised_date;
        newContract.show_info = this.state.newContractTemplate?.info
        newContract.show_text = this.state.newContractTemplate?.text
        newContract.show_void = this.state.newContractTemplate?.void

        if ((newContract?.fee_type === "Schedule F") || (((newContract?.show_guarantee === 0 && newContract?.guarentee_period === null) || (newContract?.show_guarantee === 1 && newContract?.guarentee_period !== null && newContract?.guarentee_period !== "")) && ((newContract?.show_rate === 1 && newContract?.rate !== "" && newContract?.rate !== null) || (newContract?.show_rate === 0 && newContract?.rate === null)))) {
            console.log("in if");
            console.log("newContract", newContract);
            dealContracts?.push(newContract);
            tempBackupDealContracts?.push(newContract);
            console.log("tempBackupDealContracts", tempBackupDealContracts);
            this.setState({ dealContracts, backupDealContracts: tempBackupDealContracts, showSaveCancelButtons: true, newContract: JSON.parse(JSON.stringify({ ...contractConfig })), postInitiatedNew: false }, () => {
                this.props?.updateDiscardChangesFlag(true);
                this.props?.updateDiscardChangesFlagParent(true);
                if (this.state.selectedFeeType === "Schedule F") {
                    // this.saveContract(contractsLength, "new");
                    this.saveContractTemp();
                }
                else if (((newContract?.show_guarantee === 0 && newContract?.guarentee_period === null) || (newContract?.show_guarantee === 1 && newContract?.guarentee_period !== null) || (newContract?.show_guarantee === 1 && newContract?.guarentee_period != "")) && ((newContract?.show_rate === 1 && newContract?.rate != "") || (newContract?.show_rate === 1 && newContract?.rate != null) || (newContract?.show_rate === 0 && newContract?.rate === null))) {
                    // this.saveContract(contractsLength, "new");
                    this.saveContractTemp();
                }
            })
        }
        if ((newContract?.show_rate === 1 && newContract?.rate === null) || (newContract?.show_rate === 1 && newContract?.rate === "")) {
            newContract["postInitiatedRateNew"] = true;
            this.setState({ newContract });
        }
        if ((newContract?.show_guarantee === 1 && newContract?.guarentee_period === null) || (newContract?.show_guarantee === 1 && newContract?.guarentee_period === "")) {
            newContract["postInitiatedGuarenteeNew"] = true;
            this.setState({ newContract });
        }

    }

    deleteContract = (index) => {
        let dealContracts = this.state.dealContracts
        selectedContract = dealContracts[index].performer_type + " - " + dealContracts[index].fee_type
        //this.setState({ showDelete: true, index: index });
        this.confirmContractDelete(index);
        this.setState({ index });
    }

    confirmContractDelete = (index) => {
        let dealContracts = this.state.dealContracts;
        dealContracts[index].is_delete = 1;
        let deletedContracts = this.state.deletedContracts;
        deletedContracts.push(dealContracts[index]);
        dealContracts.splice(index, 1);
        this.setState({ dealContracts, confimationMessage: null, deletingContract: true, deletedContracts }, () => {
            // this.saveContract(index);
            this.saveContractTemp();
        })
    }

    showInfoPopover = (iconPressed) => {
        this.setState({ openInfoPopover: true, tooltipBtnPressed: iconPressed })
    }

    hideInfoPopover = () => {
        this.setState({ openInfoPopover: false, tooltipBtnPressed: null })
    }

    showFileContractPopover = (iconPressed) => {
        this.setState({ openFileContractPopover: true, tooltipBtnPressed: iconPressed })
    }

    hideFileContractPopover = () => {
        this.setState({ openFileContractPopover: false, tooltipBtnPressed: null })
    }

    onConfirmationMssgChange = (value) => {
        this.setState({ confimationMessage: value })
    }

    openEsignManager = (contract_info) => {
        this.setState({ openEsignModal: true, performer_deal_contract_id: contract_info?.performer_deal_contract_id, contract_info: contract_info, file_name: contract_info?.file_name })
    }

    closeEsignManager = () => {
        this.setState({ openEsignModal: false })
    }

    openEditRiderModal = (contract_info, rider_type) => {
        this.setState({ openEditRider: true, performer_deal_contract_id: contract_info?.performer_deal_contract_id, contract_info: contract_info, rider_type: rider_type })
    }

    closeEditRiderModal = () => {
        this.setState({ openEditRider: false })
    }

    showNewInfoPopover = (iconPressed) => {

        this.setState({ openNewInfoPopover: true, tooltipBtnPressed: iconPressed })
    }
    hideNewInfoPopover = () => {
        // let backupNewContract = JSON.parse(JSON.stringify({ ...this.state.backupNewContract }));
        //let newContract = JSON.parse(JSON.stringify({ ...this.state.newContract }));
        //newContract.info = backupNewContract?.info;
        this.setState({ openNewInfoPopover: false, tooltipBtnPressed: null })
    }
    showNewFileContractPopover = (iconPressed) => {
        this.setState({ openNewContractPopover: true, tooltipBtnPressed: iconPressed })
    }
    hideNewFileContractPopover = () => {
        //let backupNewContract = JSON.parse(JSON.stringify({ ...this.state.backupNewContract }));
        //let newContract = JSON.parse(JSON.stringify({ ...this.state.newContract }));
        //newContract.text = backupNewContract?.text;
        this.setState({ openNewContractPopover: false, tooltipBtnPressed: null })
    }

    handleCheck = (e, index) => {
        let dealContracts = this.state.dealContracts
        let isChecked = e.target.checked
        if (isChecked) {
            dealContracts[index].void = 1
            dealContracts[index].total_amount = 0.00
        } else {
            dealContracts[index].void = 0
        }
        this.setState({ dealContracts: dealContracts })
    }

    handleIsRevisedChange = (e, index) => {
        let dealContracts = this.state.dealContracts
        let isChecked = e.target.checked
        if (isChecked) {
            dealContracts[index].is_revised = 1
        } else {
            dealContracts[index].is_revised = 0
        }
        this.setState({ dealContracts: dealContracts })
    }

    onNewContractPopOverChange = (field, value) => {
        this.setState((prevState) => ({
            newContract: {
                ...prevState?.newContract,
                [field]: value
            }
        }))
    }

    unsaveContract = (index) => {
        console.log("unsaveContract");
        let dealContracts = JSON.parse(JSON.stringify([...this.state.dealContracts]));
        let tempBackupDealContracts = JSON.parse(JSON.stringify([...this.state.backupDealContracts]));
        dealContracts[index] = tempBackupDealContracts[index];
        dealContracts[index].isEdit = false;
        console.log("dealContracts[index]", dealContracts[index]);
        this.setState({ dealContracts }, this.updateGlobalSaveDisability(dealContracts));
    }

    openBoxPopOver = (item, index) => {
        globalIndex = index
        let dealContracts = this.state.dealContracts
        dealContracts[index].file_uploaded_as = dealContracts[index].file_uploaded_as ? dealContracts[index].file_uploaded_as : "Unsigned"
        dealContracts[index].file_upload_type = dealContracts[index].file_upload_type ? dealContracts[index].file_upload_type : "system-generated"
        this.setState({ openPopover: true, performer_deal_contract_id: item?.performer_deal_contract_id, dealContracts: dealContracts })
    }

    closeBoxPopOver = () => {
        this.setState({ openPopover: false })
    }

    onPopOverSave = (file) => {
        if (file?.file_upload_type === "custom_file") {
            this.uploadFileToBox(file, false, "upload")
        } else if (file?.file_upload_type === "system-generated") {
            this.postBoxTemplateToAdobe(file)
        }
    }

    postBoxTemplateToAdobe = (file) => {
        this.setState({ uploadingFileToBox: true })
        let postJSON = {
            payload: "casterFeaturePostTemplateToAdobe",
            encryptedString: btoa(JSON.stringify({
                performer_deal_contract_id: file.performer_deal_contract_id,
                template_name: this.props?.loanout_present ? file.loanout_contract_template_name : file.contract_template_name,
                filename: file.file_name + '.pdf',
                save_as_draft: 0,
                url: this.props?.boxURL,
                project_id: this.props?.project_id,
                preview: 1,
                file_upload_type: file?.file_upload_type,
                file_uploaded_as: file?.file_uploaded_as
            })),
            encryptionType: 1
        }
        CasterService.getDataWS(postJSON).then(
            (webSocketConn) =>
                (webSocketConn.onmessage = (e) => {
                    let response = JSON.parse(e.data);
                    if (response?.statusCode == 200) {
                        this.setState({ uploadingFileToBox: false }, () => {
                            this.closeBoxPopOver();
                            this.getCompensationTabData();
                            this.props?.notificationComponent(true, "success");
                        })
                    } else if (response.message === "Endpoint request timed out") {
                        this.setState({ uploadingFileToBox: false })
                    }
                    else {
                        this.setState({ uploadingFileToBox: false }, () => {
                            this.props?.notificationComponent(true, "fail");
                        })
                    }
                })
        );
    }

    uploadFileToBox = (file, isReplace = false, action) => {
        if (action === "upload") {
            fileFormData = new FormData();
            fileFormData.append("files", this.state.file);
            let formattedFileList = [{
                "Key": this.state.fileName,
                "url": "",
                "id": "1",
            }];
            fileFormData.append('project_id', this.props?.project_id);
            fileFormData.append('performer_deal_contract_id', this.state?.performer_deal_contract_id);
            fileFormData.append('document_type', this.state.upload_as);
            fileFormData.append('file_type', this.state.fileType);
            fileFormData.append('url', this.props?.boxURL);
            fileFormData.append('action', action);
            fileFormData.append('file_upload_type', file.file_upload_type);
            fileFormData.append('file_uploaded_as', file.file_uploaded_as)
        }
        this.setState({ uploadingFileToBox: true, file_name: file.file_name })
        CasterService.uploadFileFormData(Constants.CasterServiceBaseUrl + `/casterFeatureManageFile`,
            fileFormData)
            .then(response => {
                console.log("check the file response", response)
                this.getCompensationTabData();
                if (response.data.message === "File uploaded successfully" && action === "upload") {
                    this.setState({ uploadingFileToBox: false }, () => {
                        this.closeBoxPopOver();
                        this.getCompensationTabData();
                        this.props?.notificationComponent(true, "success");
                    })
                    console.log("check the file response", response)
                }
                else {
                    this.closeBoxPopOver();
                    this.props?.notificationComponent(true, "fail");
                }
            },
                (err) => {
                    this.setState({ uploadingFileToBox: false }, () => {
                        this.closeBoxPopOver();
                    })
                    console.log('Error in uploading files' + err);
                });
    }

    handleFileUpload = (file, globalIndex) => {
        let dealContracts = this.state.dealContracts
        dealContracts[globalIndex].box_upload_file_name = file.name
        this.setState({ fileName: file.fileName, fileType: file.type, file: file, dealContracts: dealContracts })
    }

    pdfDownload = (contract, isReplace = false, action, index) => {
        // console.log(contract);
        globalIndex = index
        this.setState({ downloadingPDF: true })
        let postJSON = {
            payload: "casterFeaturePostTemplateToAdobe",
            encryptedString: btoa(JSON.stringify({
                performer_deal_contract_id: contract.performer_deal_contract_id,
                template_name: this.props?.loanout_present ? contract.loanout_contract_template_name : contract.contract_template_name,
                filename: contract.file_name + '.pdf',
                save_as_draft: 0,
                url: this.props?.boxURL,
                project_id: this.props?.project_id,
                preview: 1,
                contract_status: contract?.contract_status
            })),
            encryptionType: 1
        }
        console.log("post", postJSON);
        CasterService.getDataWS(postJSON).then(
            (webSocketConn) =>
                (webSocketConn.onmessage = (e) => {
                    let response = JSON.parse(e.data);
                    if (response?.statusCode == 200 && response?.body != "Uploaded file not found." && response?.body != "Upload folder not found." && response?.body != "Document path not found.") {
                        const filename = contract.file_name + '.pdf'
                        let decodedFile = decodeURIComponent(filename)
                        downloadUrl(response?.body, decodedFile);
                        this.setState({ downloadingPDF: false })
                    } else if (response?.body === "Uploaded file not found." || response?.body === "Upload folder not found." || response?.body === "Document path not found.") {
                        this.setState({ pdfDownloadFailed: true, pdfFailMssg: response?.body, downloadingPDF: false })
                    } else {
                        this.setState({ downloadingPDF: false })
                    }
                })
        );
    }

    parentalAgreementsDownload = (contract, index) => {
        globalIndex = index
        this.setState({ downloadingParentalAgreements: true })
        let postJSON = {
            payload: "casterFeatureGenerateReport",
            reportName: "rptParentalAgreement",
            fileName: "Parental Agreement",
            searchJson: btoa(JSON.stringify({ performer_deal_contract_id: contract.performer_deal_contract_id })),
            encryptionType: 1,
            riderFlag: 0
        }
        CasterService.getDataWS(postJSON).then(
            (webSocketConn) =>
                (webSocketConn.onmessage = (e) => {
                    let response = JSON.parse(e.data);
                    if (response?.statusCode == 200) {
                        const filename = contract.file_name + '.pdf'
                        let decodedFile = decodeURIComponent(filename)
                        downloadUrl(response?.body, decodedFile);
                        this.setState({ downloadingParentalAgreements: false })
                    }
                })
        );
    }
    handleOnChange = (value) => {

        if (this.state.openInfoPopover) {
            this.onContractChange(globalIndex, "info", value);
        } else {
            this.onContractChange(globalIndex, "text", value)
        }
    }
    handleOnChangeText = (value) => {

        if (this.state.openNewInfoPopover) {
            this.onNewContractPopOverChange("info", value);
        } else {
            this.onNewContractPopOverChange("text", value);
        }
    }
    onConfirmingContractCancellation = () => {
        if (this.state.newOrGlobal === "newContract") {
            this.setState({ newContract: JSON.parse(JSON.stringify({ ...contractConfig })), showCancelContractModal: false, selectedPerformerType: null, selectedFeeType: null });
        } else {
            this.setState({ dealContracts: JSON.parse(JSON.stringify([...this.state.initialDealContracts])), showCancelContractModal: false, showSaveCancelButtons: false });
            this.props?.updateDiscardChangesFlag(false);
            this.props?.updateDiscardChangesFlagParent(false);
        }

    }
    onCancelContract = (newOrGlobal) => {
        this.setState({ showCancelContractModal: true, newOrGlobal });
    }

    render() {
        let deleteModalTitle = "Confirm Contract Deletion";
        let discardChanges = "Discard unsaved changes";
        let discardChangesOnTabChange = "Are you sure to discard unsaved contract details?";
        let confirmCancelNewContractMsg = "Are you sure to cancel unsaved contract details?";
        let cancelContractMessage = "Confirm Contract Cancellation"
        let deleteMessage = `Are you sure you would like to delete this ${selectedContract} contract?`;



        return (
            <div className="compensation-tab-container">
                <MessageModal
                    open={this.state.pdfDownloadFailed || this.state.showCancelContractModal || this.props?.showdiscardNotification || this.props?.showdiscardNotificationParent || false}
                    title={(this.state.pdfDownloadFailed && "PDF Download") || (this.state.showCancelContractModal && cancelContractMessage) || ((this.props?.showdiscardNotification || this.props?.showdiscardNotificationParent) ? discardChanges : deleteModalTitle)}
                    primaryButtonIcon={(this.state.pdfDownloadFailed || this.state.showCancelContractModal || this.props?.showdiscardNotification || this.props?.showdiscardNotificationParent) ? "" : "trash"}
                    secondaryButtonIcon="times"
                    inputFieldRequired={false}
                    hideCancel={this.state.pdfDownloadFailed ? true : false}
                    confimationMessage={this.state.confimationMessage}
                    message={(this.state.pdfDownloadFailed && this.state.pdfFailMssg) || (this.state.showCancelContractModal && confirmCancelNewContractMsg) || ((this.props?.showdiscardNotification || this.props?.showdiscardNotificationParent) ? discardChangesOnTabChange : deleteMessage)}
                    primaryButtonText={(this.state.pdfDownloadFailed && "OK") || ((this.state.showCancelContractModal || this.props?.showdiscardNotification || this.props?.showdiscardNotificationParent) && "Confirm")}
                    secondaryButtonText={"Cancel"}
                    onConfirm={(this.state.pdfDownloadFailed && this.setState({ pdfDownloadFailed: false })) || (this.state.showCancelContractModal && (() => this.onConfirmingContractCancellation())) || (this.props?.showdiscardNotification && (() => this.props?.refreshDiscardChangesFlag("confirm"))) || (this.props?.showdiscardNotificationParent && (() => this.props?.refreshDiscardChangesFlagParent("confirm")))}
                    handleClose={(this.state.showCancelContractModal && ((e) => this.setState({ showCancelContractModal: false }))) || (this.props?.showdiscardNotification && (() => this.props?.refreshDiscardChangesFlag())) || (this.props?.showdiscardNotificationParent && (() => this.props?.refreshDiscardChangesFlagParent()))} />
                <MDBCol>
                    {!this.props?.summary ?
                        <MDBRow className="summaryRow">
                            <MDBCol md={4}>
                            {this.props?.userContext?.user_profile?.user_role_name !== "Read Only" && 
                                <SelectField
                                    placeHolderText="Performer Type"
                                    options={this.state.performerTypeOptions}
                                    value={this.state.selectedPerformerType}
                                    // onChange={(e) => this.onPerformerSelect(e.target.value) }
                                    onChange={(e) => {
                                        this.setState({ selectedPerformerType: e.target.value, newContractTemplate: null, selectedFeeType: null }, () => {
                                            this.getSearchValues("FEE_TYPE", this.props?.contract_type + "_" + e.target.value);
                                        })
                                    }}
                                />}
                            </MDBCol>
                            <MDBCol md={4}>
                            {this.props?.userContext?.user_profile?.user_role_name !== "Read Only" && 
                                <SelectField
                                    placeHolderText="Fee Type"
                                    options={this.state.feeOptions}
                                    value={this.state.selectedFeeType}
                                    onChange={(e) => this.setState({ selectedFeeType: e.target.value, contractConfigLoading: true, newContractTemplate: null }, () => {
                                        this.getSearchValues("CONTRACT_CONFIG", this.props?.contract_type + "_" + this.state.selectedPerformerType + "_" + e.target.value.replaceAll("%", "_").replaceAll("+", "pl"));
                                    })}
                                />}
                            </MDBCol>
                            {console.log("this.state.showSaveCancelButtons ", this.state.showSaveCancelButtons)}
                            {this.state.showSaveCancelButtons === true ? <MDBCol md={2} className="d-flex justify-content-end saveCancelWrapper">
                              
                                <BasicButton disabled={this.state.disableGlobalSave === true ? true : false}
                                    type="inline"
                                    variant="contained"
                                    text={this.state.postingContract ? <CircularProgress color="inherit" size={20} /> : "save"}
                                    icon={"save"}
                                    onClick={() => this.saveContract()}
                                />

                                <BasicButton
                                    type="inline"
                                    variant="outlined"
                                    text={"Cancel"}
                                    icon="times"
                                    onClick={() => this.onCancelContract("global")}
                                />
                            </MDBCol> : <MDBCol md={2}></MDBCol>}
                            {/* <MDBCol md={2}></MDBCol> */}
                            {this.state.newContractTemplate?.descript == 1 ?
                                <MDBCol md={2} className="new-descript-checkbox">
                                    <BasicCheckbox
                                        id="descript"
                                        label="Descript"
                                        checked={this.state.newContract?.descript === 1}
                                        onChange={(e) => this.onNewContractChange("descript", e.target.checked)}
                                    />
                                </MDBCol> : <MDBCol md={2}></MDBCol>
                            }
                        </MDBRow> : null}
                    <br />
                    {this.state.contractConfigLoading ? <CircularProgress color="inherit" size={20} /> :
                        <>
                            {this.state.selectedPerformerType != null && this.state.selectedFeeType != null ?
                                <div className="new-contract-wrap">
                                    <div className={(this.state.selectedPerformerType === "Performer" && this.state.selectedFeeType === "Other Daily") || (this.state.selectedPerformerType === "Performer" && this.state.selectedFeeType === "Other Weekly") || this.state.selectedFeeType === "Schedule F - Weekly" || this.state.selectedFeeType === "Schedule F - Daily" || (this.state.selectedPerformerType === "Performer" && this.state.selectedFeeType === "SAG Daily Above Scale") ? "new-contract longContent" : "new-contract shortContent"}>
                                        {this.state.newContractTemplate?.deal_date == 1 &&
                                            <div className="contract-date">
                                                <DateField
                                                    id="new_deal_date"
                                                    label="Deal Date"
                                                    value={this.state.newContract?.deal_date}
                                                    onChange={(e) => this.onNewContractChange("deal_date", e.target.value?.length > 0 ? e.target.value : null)}
                                                />
                                            </div>
                                        }
                                        {this.state.newContractTemplate?.rate == 1 &&
                                            <div className="rate-cotainer newContractMarginLeft">
                                                <div className="contract-rate">
                                                    <SelectField
                                                        className="contract-rate-select"
                                                        label="Rate"
                                                        isMandatory={this.state.selectedFeeType === "Schedule F" ? false : true}
                                                        showMandatory={this.state.newContract?.postInitiatedRateNew || false}
                                                        options={this.state.currencyOptions}
                                                        value={this.state.newContract?.currency === undefined || (this.state.newContract?.currency === "$" && this.state.newContract?.currency_code === "$") ? this.state.newContract?.currency_code : this.state.newContract?.currency_code + " " + this.state.newContract?.currency}
                                                        onChange={(e) => this.onNewContractChange("currency", e.target.value)}
                                                    />
                                                </div>
                                                <div className="contact-amount">
                                                    <BasicTextField
                                                        label=" "
                                                        value={this.state.newContract?.rate}
                                                        showMandatory={this.state.newContract?.postInitiatedRateNew || false}
                                                        onChange={(e) => this.onNewContractChange("rate", e.target.value)}
                                                        mandatoryWarning="Required"
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {this.state.newContractTemplate?.guarantee == 1 &&
                                            <div className={this.state.newContract?.guarentee_period_type ? "guarantee-container newContractMarginLeft" : "guarantee-container empty newContractMarginLeft"}>
                                                <div className={this.state.newContract?.guarentee_period_type ? "guarantee-text-field" : "guarantee-text-field empty"}>
                                                    <BasicTextField
                                                        label="Guarantee"
                                                        value={this.state.newContract?.guarentee_period}
                                                        isMandatory={this.state.selectedFeeType === "Schedule F" ? false : true}
                                                        showMandatory={this.state.newContract?.postInitiatedGuarenteeNew || false}
                                                        onChange={(e) => this.onNewContractChange("guarentee_period", e.target.value)}
                                                        mandatoryWarning="Required"
                                                    />
                                                </div>
                                                <div className={this.state.newContract?.guarentee_period_type ? "guarantee-select" : "guarantee-select empty"}>
                                                    {this.state.newContract?.guarentee_period_type}
                                                </div>
                                            </div>
                                        }
                                        {this.state.newContractTemplate?.period == 1 &&
                                            <div className="period-col newContractMarginLeft">
                                                <SelectField
                                                    id={"newPeriod"}
                                                    className="period-select"
                                                    label="Period"
                                                    options={this.state.periodOptions}
                                                    value={this.state.newContract?.period}
                                                    onChange={(e) => this.onNewContractChange("period", e.target.value)}
                                                />
                                            </div>
                                        }
                                        {this.state.newContractTemplate?.start_date == 1 &&
                                            <div className="start-date-col newContractMarginLeft">
                                                <SelectField
                                                    id={"new_start_date_qualifier"}
                                                    label=" "
                                                    className="date-qualifier"
                                                    options={this.state.qualfier_options}
                                                    value={this.state.newContract?.start_date_qualifier}
                                                    onChange={(e) => this.onNewContractChange("start_date_qualifier", e.target.value)}
                                                />

                                                <DateField
                                                    id="new_start_date"
                                                    label="Start Date"
                                                    value={this.state.newContract?.start_date}
                                                    onChange={(e) => this.onNewContractChange("start_date", e.target.value?.length > 0 ? e.target.value : null)}
                                                />
                                            </div>
                                        }
                                        {this.state.newContractTemplate?.plus_or_minus_group == 1 &&
                                            <div className="plus-minus-container newContractMarginLeft">
                                                <div className="plus-minus-field-col">
                                                    <BasicTextField
                                                        label="+/-"
                                                        value={this.state.newContract?.plus_or_minus_period}
                                                        onChange={(e) => {
                                                            this.onNewContractChange("plus_or_minus_period", e.target.value?.length > 0 ? e.target.value : null)
                                                            // this.onNewContractChange("plus_or_minus_type", e.target.value === "" ? null : this.state.newContract?.guarentee_period_type)
                                                        }}
                                                    />
                                                </div>
                                                <div className="plus-minus-select-col">
                                                    {/* {this.state.newContract?.guarentee_period_type} */}
                                                    <SelectField
                                                        id={"plus_or_minus_type"}
                                                        label=" "
                                                        className="plus-minus-select-field"
                                                        value={this.state.newContract?.plus_or_minus_type || this.state.newContract?.guarentee_period_type}
                                                        options={this.state.plusMinusOptions}
                                                        onChange={(e) => this.onNewContractChange("plus_or_minus_type", e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {this.state.newContractTemplate?.principal_free_group == 1 &&
                                            <div className="principal-free-container newContractMarginLeft">
                                                <BasicLabel text="Principal Free" />
                                                <div className="principal-free-field-col">
                                                    <BasicTextField
                                                        // label="Principal free"
                                                        value={this.state.newContract?.principal_free_period}
                                                        onChange={(e) => {
                                                            this.onNewContractChange("principal_free_period", e.target.value?.length > 0 ? e.target.value : null)
                                                            // this.onNewContractChange("principal_free_type", e.target.value === "" ? null : this.state.newContract?.guarentee_period_type)
                                                        }}
                                                    />
                                                </div>
                                                <div className="principal-free-select-col">
                                                    {/* {this.state.newContract?.guarentee_period_type} */}
                                                    <SelectField
                                                        id={"principal_free_type"}
                                                        className="principal-free-select-field"
                                                        value={this.state.newContract?.principal_free_type || this.state.newContract?.guarentee_period_type}
                                                        options={this.state.principalPeriodOptions}
                                                        onChange={(e) => this.onNewContractChange("principal_free_type", e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        {this.state.newContractTemplate?.post_free_group == 1 &&
                                            <div className="post-free-container newContractMarginLeft">
                                                <BasicLabel text="Post Free" />

                                                <div className="post-free-field-col">
                                                    <BasicTextField
                                                        // label="Post Free"
                                                        value={this.state.newContract?.post_free_period}
                                                        onChange={(e) => {
                                                            this.onNewContractChange("post_free_period", e.target.value?.length > 0 ? e.target.value : null)
                                                            // this.onNewContractChange("post_free_type", e.target.value === "" ? null : this.state.newContract?.guarentee_period_type)
                                                        }}
                                                    />
                                                </div>
                                                <div className="post-free-select-col">
                                                    {/* {this.state.newContract?.guarentee_period_type} */}
                                                    <SelectField
                                                        id={"post_free_type"}
                                                        className="post-free-select-field"
                                                        options={this.state.postFreeOptions}
                                                        value={this.state.newContract?.post_free_type || this.state.newContract?.guarentee_period_type}
                                                        onChange={(e) => this.onNewContractChange("post_free_type", e.target.value)}
                                                    />
                                                </div>

                                            </div>
                                        }
                                        {this.state.newContractTemplate?.basic_free_group == 1 &&
                                            <div className="basic-free-container newContractMarginLeft">
                                                <BasicLabel text="Free Days" />

                                                <div className="basic-free-text-field">
                                                    <BasicTextField
                                                        // label="Basic Free"
                                                        value={this.state.newContract?.basic_free_period}
                                                        onChange={(e) => {
                                                            this.onNewContractChange("basic_free_period", e.target.value?.length > 0 ? e.target.value : null)
                                                            // this.onNewContractChange("basic_free_type", e.target.value === "" ? null : this.state.newContract?.guarentee_period_type)
                                                        }}
                                                    />
                                                </div>
                                                <div className="basic-free-select-field-col">
                                                    {/* {this.state.newContract?.guarentee_period_type} */}
                                                    <SelectField
                                                        id={"basic_free_type"}
                                                        className="basic-free-select-field"
                                                        options={this.state.basicFreeOptions}
                                                        value={this.state.newContract?.basic_free_type || this.state.newContract?.guarentee_period_type}
                                                        onChange={(e) => this.onNewContractChange("basic_free_type", e.target.value)}
                                                    />
                                                </div>

                                            </div>
                                        }
                                        {this.state.newContractTemplate?.sent_date == 1 &&
                                            <div className="sent-date-col newContractMarginLeft">
                                                <DateField
                                                    id="new_sent_date"
                                                    label="Sent"
                                                    value={this.state.newContract?.sent_date}
                                                    onChange={(e) => this.onNewContractChange("sent_date", e.target.value?.length > 0 ? e.target.value : null)}
                                                />
                                            </div>
                                        }
                                        {this.state.newContractTemplate?.returned_date == 1 &&
                                            <div className="returned-date-col newContractMarginLeft">
                                                <DateField
                                                    id="new_returned_date"
                                                    label="Returned"
                                                    value={this.state.newContract?.returned_date}
                                                    onChange={(e) => this.onNewContractChange("returned_date", e.target.value?.length > 0 ? e.target.value : null)}
                                                />
                                            </div>
                                        }
                                        {this.state.newContractTemplate?.revised_date == 1 &&
                                            <div className="revised-date-col newContractMarginLeft">
                                                <DateField
                                                    id="new_revised_date"
                                                    label="Revised"
                                                    value={this.state.newContract?.revised_date}
                                                    onChange={(e) => this.onNewContractChange("revised_date", e.target.value?.length > 0 ? e.target.value : null)}
                                                />
                                            </div>
                                        }
                                        <div className="new-contract-icon-container newContractMarginLeft">
                                            <div className="contracts-icons">
                                                <BasicLabel className="info-heading" text="Info" />
                                                <div className="textInfoIcons">
                                                    {this.state.newContract?.info ? <MDBTooltip wrapperProps={{ color: 'secondary' }} title={ReactHtmlParser(this.state.newContract?.info)}><span><MDBIcon id="info-button" className={this.state.newContract?.info ? "green-icon" : "empty"} fas icon="info" onClick={() => this.showNewInfoPopover("newInfo")} /></span></MDBTooltip> : <MDBIcon id="info-button" className={this.state.newContract?.info ? "green-icon" : "empty"} fas icon="info" onClick={() => this.showNewInfoPopover("newInfo")} />}
                                                </div>
                                            </div>
                                            <div className="contracts-icons">
                                                <BasicLabel className="text-heading" text="Text" />
                                                <div className="textInfoIcons">
                                                    {this.state.newContract?.text ? <MDBTooltip wrapperProps={{ color: 'secondary' }} title={ReactHtmlParser(this.state.newContract?.text)}><span><MDBIcon id="file-contract-button" className={this.state.newContract?.text ? "green-icon" : "empty"} fas icon="file-contract" onClick={() => this.showNewFileContractPopover("newText")} /></span></MDBTooltip> : <MDBIcon id="file-contract-button" className={this.state.newContract?.text ? "green-icon" : "empty"} fas icon="file-contract" onClick={() => this.showNewFileContractPopover("newText")} />}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="Total-amount newContractMarginLeft">
                                            {/* <MDBRow>Total Amount</MDBRow> */}
                                            <BasicLabel text="Total Amount" />
                                            <div className="total_amount_no">{this.state.newContract?.currency_code} {this.state.newContract?.guarentee_period && this.state.selectedFeeType != "Schedule F - Daily" && this.state.selectedFeeType != "Schedule F - Weekly" ? formatRate(this.state.newContract?.rate * this.state.newContract?.guarentee_period) : formatRate(this.state.newContract?.rate * 1)}</div>
                                        </div>
                                    </div>
                                    <div className="add-contract-button">
                                        <AddCircleIcon onClick={() => this.addNewContract()} />
                                        <CancelIcon onClick={() => this.onCancelContract("newContract")} />
                                    </div>
                                </div>
                                : ( this.props?.summary ? null : (
                                    this.props?.userContext?.user_profile?.user_role_name !== "Read Only" && (
                                <div className="description-text">Select a Performer/Fee Type to add a contract</div>)))}
                        </>}
                    <br />
                    {!this.state.loadingContracts ? (this.state.dealContracts?.length > 0 ? this.state.dealContracts?.filter(i => i?.is_delete == 0)?.map((item, index) => (
                        <div className={(item?.performer_type === "Performer" && item?.fee_type === "Other Daily") || (item?.performer_type === "Performer" && item?.fee_type === "Other Weekly") || (item?.performer_type === "Performer" && item?.fee_type === "SAG Daily Above Scale") ? "striped longContent" : "striped shortContent"}>
                            <MDBRow className="heading-row">
                                <MDBCol md={7} className="d-flex"><span className="title">{item?.performer_type} - {item?.fee_type}</span> <span className="void-text">{item?.void == 1 ? "VOIDED " : " "}</span> <span className="revised-text">{item?.revised_date != null ? "REVISED" : ""} </span> <span className={item?.isEdit == 1 ? "descript-checkbox" : "descript"}>{item?.descript === 1 && item.isEdit != 1 ? "Descript" : item?.show_descript == 1 && item.isEdit == 1 ? <BasicCheckbox id="contract-descript" label="Descript" checked={item?.descript === 1} onChange={(e) => this.onContractChange(index, "descript", e.target.checked)} /> : null}</span></MDBCol>
                                {/* <MDBCol md={1} className="contract-status d-flex"></MDBCol> */}
                                <MDBCol md={5} id="icon-group">
                                    {!this.props?.summary ?
                                        <>
                                            <Tooltip title="Esign Manager"><span><MDBIcon fas icon="mail-bulk" onClick={() => this.openEsignManager(item)} /></span></Tooltip>
                                            {this.props?.age !== null && this.props?.age < 18 ?
                                                (globalIndex === index && this.state.downloadingParentalAgreements ? <CircularProgress color="inherit" size={20} /> : <Tooltip title="Parental Agreements"><span><MDBIcon fas icon="users" onClick={() => this.parentalAgreementsDownload(item, index)} /></span></Tooltip>) : null
                                            }
                                            {globalIndex === index && this.state.downloadingPDF ? <CircularProgress color="inherit" size={20} /> : item?.contract_template_name !== "N/A" ? <Tooltip title="PDF Download"><span><MDBIcon fas icon="file-pdf" onClick={() => this.pdfDownload(item, 0, "download", index)} /></span></Tooltip> : ""}
                                            <Tooltip title="Rider"><span><MDBIcon fas icon="file-medical" className={item?.riders?.find(item => item?.rider_type === "From Memos")?.is_checked === 1 ? "green-color-icon" : ""} onClick={() => this.openEditRiderModal(item, "From Memos")} /></span></Tooltip>
                                            <Tooltip title="Exhibit A Rider"><span><MDBIcon fas icon="file-video" className={item?.riders?.find(item => item?.rider_type === "Exhibit A")?.is_checked === 1 ? "green-color-icon" : ""} onClick={() => this.openEditRiderModal(item, "Exhibit A")} /></span></Tooltip>
                                            <Tooltip title="Box Upload"><span><MDBIcon id={`box`} fas icon="box" className={item?.box_file_id ? "green-color-icon" : ""} onClick={() => this.openBoxPopOver(item, index)} /></span></Tooltip>
                                            <Popover
                                                open={this.state.openPopover}
                                                anchorEl={document?.getElementById('box')}
                                                getContentAnchorEl={null}
                                                onClose={this.closeBoxPopOver}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                }}
                                                PaperProps={{ style: { width: '30%', backgroundColor: '#F2F2F2' } }}
                                            >
                                                <div className="box-popover-container">
                                                    <div className="box-popover-heading">Note: Contracts sent/signed through E-Sign will be auto-uploaded to Box.</div>
                                                    {this.props?.boxURL === null || this.props?.boxURL === "" ? <div className="box-url-error-heading">This project doesn't has a Box URL</div> : null}
                                                    <div><b>Upload As</b></div>
                                                    <MDBRow>
                                                        <MDBCol md={4} className="d-flex">
                                                            <Radio
                                                                // value="Unsigned"
                                                                size="small"
                                                                checked={this.state.dealContracts[globalIndex]?.file_uploaded_as === "Unsigned" ? 1 : 0}
                                                                onChange={() => this.onContractChange(globalIndex, "file_uploaded_as", "Unsigned")}
                                                            />
                                                            Un-signed
                                                        </MDBCol>
                                                        <MDBCol md={4} className="d-flex">
                                                            <Radio
                                                                // value="Signed"
                                                                size="small"
                                                                checked={this.state.dealContracts[globalIndex]?.file_uploaded_as === "Signed" ? 1 : 0}
                                                                onChange={() => this.onContractChange(globalIndex, "file_uploaded_as", "Signed")}
                                                            />
                                                            Signed
                                                        </MDBCol>
                                                    </MDBRow>
                                                    <div><b>File to Upload</b></div>
                                                    <MDBRow>
                                                        {this.state.dealContracts[globalIndex]?.file_uploaded_as !== "Signed" ?
                                                            <MDBCol md={4}>
                                                                <Radio
                                                                    value="system-generated"
                                                                    size="small"
                                                                    checked={this.state.dealContracts[globalIndex]?.file_upload_type === "system-generated" ? 1 : 0}
                                                                    onChange={() => this.onContractChange(globalIndex, "file_upload_type", "system-generated")}
                                                                />
                                                            System-generated
                                                        </MDBCol> : null}
                                                        <MDBCol md={4}>
                                                            <Radio
                                                                value="Custom File"
                                                                size="small"
                                                                checked={this.state.dealContracts[globalIndex]?.file_upload_type === "custom_file" ? 1 : 0}
                                                                onChange={() => this.onContractChange(globalIndex, "file_upload_type", "custom_file")}
                                                            />
                                                            Custom File
                                                        </MDBCol>
                                                        <MDBCol md={2}>
                                                        {this.props?.userContext?.user_profile?.user_role_name !== "Read Only" &&  
                                                         <BasicButton icon="upload" inputType="file" id="contractFileBoxUpload" disabled={this.state.dealContracts[globalIndex]?.file_upload_type === "custom_file" ? false : true} uploadFile={(file) => this.handleFileUpload(file, globalIndex)} />}
                                                        </MDBCol>
                                                    </MDBRow>
                                                    <div>
                                                        {this.state.dealContracts[globalIndex]?.box_upload_file_name ? this.state.dealContracts[globalIndex]?.box_upload_file_name : null}
                                                    </div>
                                                    <MDBRow>
                                                        <MDBCol md={8}></MDBCol>
                                                        {this.props?.userContext?.user_profile?.user_role_name !== "Read Only" &&
                                                        <MDBCol md={2}><BasicButton variant="contained" type="inline" icon={this.state.uploadingFileToBox ? "" : "save"} text={this.state.uploadingFileToBox ? <CircularProgress color="white" size={15} /> : " "} onClick={() => this.onPopOverSave(this.state.dealContracts[globalIndex], 0, "upload")} disabled={this.props?.boxURL === null || this.props?.boxURL === "" ? true : false} /></MDBCol>
                                                        }
                                                        <MDBCol md={2}><BasicButton variant="outlined" type="inline" icon="times" text=" " onClick={() => this.closeBoxPopOver()} /></MDBCol>
                                                    </MDBRow>
                                                </div>
                                            </Popover>
                                        </>
                                        : null}
                                    <span>{item?.contract_template_name === "N/A" ? "External Document" : item?.contract_status === "Not Sent" ? " " : item?.contract_status}</span>
                                </MDBCol>
                            </MDBRow>
                            <div className="contract-data-row">
                                <div className="contract-scroll">

                                    {item?.show_deal_date == 1 ? item?.isEdit == 1 ?
                                        <div className="displayInlineBlock deal-date-edit editClass editLeftMargin" >
                                            <DateField tabIndex={0}
                                                id={"deal_date" + index}
                                                label="Deal Date"
                                                value={item?.deal_date}
                                                onChange={(e) => this.onContractChange(index, "deal_date", e.target.value?.length > 0 ? e.target.value : null)}
                                            />
                                        </div>
                                        :
                                        <div className="widthAuto deal-date-read readClass" >
                                            <div><b className="paddingLeft0">Deal Date</b></div>
                                            {item?.deal_date ? <div>{getFormattedDate(item?.deal_date)}</div> : <div></div>}
                                        </div> : null
                                    }

                                    {item?.show_rate == 1 ? item.isEdit == 1 ?
                                        <div className="displayInlineBlock rate-edit editClass editLeftMargin" >
                                            <SelectField tabIndex={0}
                                                id={"rate" + index}
                                                className="contract-rate-select"
                                                label="Rate"
                                                isMandatory={item?.fee_type != "Schedule F" ? true : false}
                                                showMandatory={item?.postInitiatedRate || false}
                                                options={this.state.currencyOptions}
                                                value={item?.currency === undefined || (item?.currency === "$" && item?.currency_code === "$") ? item?.currency_code : item?.currency_code + " " + item?.currency}
                                                onChange={(e) => this.onContractChange(index, "currency", e.currentTarget.innerText)}

                                            />
                                            <BasicTextField tabIndex={0}
                                                label=" "
                                                value={item?.rate}
                                                showMandatory={item?.postInitiatedRate || false}
                                                onChange={(e) => this.onContractChange(index, "rate", e.target.value?.length > 0 ? e.target.value : null)}
                                                mandatoryWarning="Required"
                                            />
                                        </div>
                                        :
                                        <div className="widthAuto readMarginLeft rate-read readClass">
                                            <div><b className="paddingLeft0">Rate</b></div>
                                            <div>{item?.currency != "$" && item?.currency != null ? item?.currency : ""} {item?.currency_code} {item?.rate ? formatRate(Number(item?.rate)) : ""} </div>
                                        </div> : null
                                    }
                                    {(item?.show_guarantee == 1) ? item.isEdit == 1 ?
                                        <div className="guarentee-edit editClass editLeftMargin" >
                                            <BasicTextField className={item?.guarentee_period_type ? "guaranteeValueWithGuarenteePeriod" : "guaranteeValueWithoutGuarenteePeriod"}
                                                label="Guarantee" tabIndex={0}
                                                value={item?.guarentee_period}
                                                isMandatory={item?.fee_type != "Schedule F" ? true : false}
                                                showMandatory={item?.postInitiatedGuarentee || false}
                                                onChange={(e) => this.onContractChange(index, "guarentee_period", e.target.value?.length > 0 ? e.target.value : null)}
                                                mandatoryWarning="Required"
                                            />
                                            <div className={item?.guarentee_period_type ? "guarenteePeriod" : ""}>{item?.guarentee_period === 1 ? (item?.guarentee_period_type === null ? "" : item?.guarentee_period_type == "Days" ? "Day" : "Week") : item?.guarentee_period_type}</div>
                                        </div> :
                                        <div className="widthAuto readMarginLeft guarentee-read readClass" >
                                            <div><b className="paddingLeft0">Guarantee</b></div>
                                            <div>{item?.guarentee_period ? item?.guarentee_period : " "} {item?.guarentee_period === 1 ? (item?.guarentee_period_type === null ? "" : item?.guarentee_period_type == "Days" ? "Day" : "Week") : item?.guarentee_period_type}</div>
                                        </div> : null
                                    }
                                    {(item?.show_period == 1) ? item.isEdit == 1 ?
                                        <div className="displayInlineBlock period-edit editClass editLeftMargin" >
                                            <SelectField tabIndex={0}
                                                id={"period" + index}
                                                className="period-select"
                                                label="Period"
                                                options={this.state.periodOptions}
                                                value={item?.period}
                                                onChange={(e) => this.onContractChange(index, "period", e.target.value)}
                                            />
                                        </div>
                                        :
                                        <div className="widthAuto readMarginLeft period-read readClass">
                                            <div><b>Period</b></div>
                                            {item?.period ? <div>{item?.period}</div> : <div className="noValue">--</div>}
                                        </div> : null
                                    }
                                    {(item?.show_start_date == 1) ? (item?.isEdit == 1) ?
                                        <div className="displayInlineBlock start-date-edit editClass editLeftMargin" >
                                            <SelectField tabIndex={0}
                                                id={"start_date_qualifier" + index}
                                                label=""
                                                className="date-qualifier"
                                                options={this.state.qualfier_options}
                                                value={item?.start_date_qualifier}
                                                onChange={(e) => this.onContractChange(index, "start_date_qualifier", e.target.value)}
                                            />
                                            <DateField tabIndex={0} label="Start Date"
                                                id={"start_date" + index}
                                                value={item?.start_date}
                                                onChange={(e) => this.onContractChange(index, "start_date", e.target.value?.length > 0 ? e.target.value : null)}
                                            />
                                        </div>
                                        :
                                        <div className="widthAuto readMarginLeft  start-date-read readClass">
                                            <div><b>Start Date</b></div>
                                            <div>
                                                {item?.start_date_qualifier ?
                                                    <>
                                                        {item?.start_date_qualifier ? <span> {item?.start_date_qualifier + " "}</span> : <span></span>}
                                                    </> : null}
                                                <>
                                                    {item?.start_date ? <span>{getFormattedDate(item?.start_date)}</span> : <div className="noValue">--</div>}
                                                </>
                                            </div>
                                        </div> : null
                                    }
                                    {(item?.show_plus_or_minus_group == 1) ? item.isEdit == 1 ?
                                        <div className="displayInlineBlock plus-minus-edit editClass editLeftMargin" >
                                            <BasicTextField tabIndex={0}
                                                label="+/-"
                                                value={item?.plus_or_minus_period}
                                                onChange={(e) => {
                                                    this.onContractChange(index, "plus_or_minus_period", e.target.value?.length > 0 ? e.target.value : null)
                                                    // this.onContractChange(index, "plus_or_minus_type", e.target.value === "" ? null : item?.guarentee_period_type)
                                                }}
                                            />
                                            <SelectField tabIndex={0}
                                                id={"plus_or_minus_type" + index}
                                                label=""
                                                className="plus-minus-select-field"
                                                value={item?.plus_or_minus_type || item?.guarentee_period_type}
                                                options={this.state.plusMinusOptions}
                                                onChange={(e) => this.onContractChange(index, "plus_or_minus_type", e.target.value)}
                                            />
                                        </div> :
                                        <div className="widthAuto readMarginLeft plus-minus-read readClass">
                                            <div><b>{item?.plus_or_minus ? item?.plus_or_minus : "+/- "}</b></div>
                                            {item?.plus_or_minus_period ? <div>{item?.plus_or_minus_period}{item?.plus_or_minus_type}</div> : <div className="noValue">--</div>}

                                        </div> : null
                                    }
                                    {item?.show_principal_free_group == 1 ? item.isEdit == 1 ?
                                        <div className="displayInlineBlock principal-free-edit editClass editLeftMargin" >
                                            <BasicTextField
                                                label="Principal free"
                                                value={item?.principal_free_period}
                                                onChange={(e) => {
                                                    this.onContractChange(index, "principal_free_period", e.target.value?.length > 0 ? e.target.value : null)
                                                    // this.onContractChange(index, "principal_free_type", e.target.value === "" ? null : item?.guarentee_period_type)
                                                }}
                                            />
                                            <SelectField
                                                id={"principal_free_type" + index}
                                                className="principal-free-select-field"
                                                value={item?.principal_free_type || item?.guarentee_period_type}
                                                options={this.state.principalPeriodOptions}
                                                onChange={(e) => this.onContractChange(index, "principal_free_type", e.target.value)}
                                            />

                                        </div>
                                        // <div className="principal-free-container">
                                        //     <BasicLabel text="Principal Free" />
                                        //     <div className="d-flex">
                                        //         <div className="principal-free-field-col">
                                        //             <BasicTextField
                                        //                 value={item?.principal_free_period}
                                        //                 onChange={(e) => {
                                        //                     this.onContractChange(index, "principal_free_period", e.target.value === "" ? null : e.target.value)
                                        //                     // this.onContractChange(index, "principal_free_type", e.target.value === "" ? null : item?.guarentee_period_type)
                                        //                 }}
                                        //             />
                                        //         </div>
                                        //         <div className="principal-free-select-col">

                                        //             <SelectField
                                        //                 className="principal-free-select-field"
                                        //                 value={item?.principal_free_type || item?.guarentee_period_type}
                                        //                 options={this.state.principalPeriodOptions}
                                        //                 onChange={(e) => this.onContractChange(index, "principal_free_type", e.target.value)}
                                        //             />
                                        //         </div>
                                        //     </div>
                                        // </div> 

                                        :
                                        <div className="widthAuto readMarginLeft principal-free-read readClass">
                                            <b>Principal free</b>
                                            {item?.principal_free_period ? <div>{item?.principal_free_period}{item?.principal_free_type}</div> : <div className="noValue">--</div>}
                                        </div> : null
                                    }
                                    {(item?.show_post_free_group == 1) ? item.isEdit == 1 ?
                                        // <div className="post-free-container">
                                        //     <BasicLabel text="Post Free" />
                                        //     <div className="d-flex">
                                        //         <div className="post-free-field-col">
                                        //             <BasicTextField
                                        //                 // label="PostFree"
                                        //                 value={item?.post_free_period}
                                        //                 onChange={(e) => {
                                        //                     this.onContractChange(index, "post_free_period", e.target.value === "" ? null : e.target.value)
                                        //                     // this.onContractChange(index, "post_free_type", e.target.value === "" ? null : item?.guarentee_period_type)
                                        //                 }}
                                        //             />
                                        //         </div>
                                        //         <div className="post-free-select-col">
                                        //             {/* {item?.post_free_type || item?.guarentee_period_type } */}
                                        //             <SelectField
                                        //                 className="post-free-select-field"
                                        //                 options={this.state.postFreeOptions || item?.guarentee_period_type}
                                        //                 value={item?.post_free_type}
                                        //                 onChange={(e) => this.onContractChange(index, "post_free_type", e.target.value)}
                                        //             />
                                        //         </div>
                                        //     </div>
                                        // </div>
                                        <div className="displayInlineBlock post-free-edit editClass editLeftMargin" >
                                            {/* <BasicLabel text="Post Free" /> */}

                                            <BasicTextField
                                                label="Post Free"
                                                value={item?.post_free_period}
                                                onChange={(e) => {
                                                    this.onContractChange(index, "post_free_period", e.target.value?.length > 0 ? e.target.value : null)
                                                    // this.onContractChange(index, "post_free_type", e.target.value === "" ? null : item?.guarentee_period_type)
                                                }}
                                            />
                                            <SelectField
                                                id={"post_free_type" + index}
                                                className="post-free-select-field"
                                                options={this.state.postFreeOptions || item?.guarentee_period_type}
                                                value={item?.post_free_type}
                                                onChange={(e) => this.onContractChange(index, "post_free_type", e.target.value)}
                                            />

                                        </div> :
                                        <div className="widthAuto readMarginLeft post-free-read readClass">
                                            <b className="paddingLeft0">Post free</b>
                                            {item?.post_free_period ? <div>{item?.post_free_period}{item?.post_free_type}</div> : <div className="noValue">--</div>}
                                        </div> : null
                                    }
                                    {(item?.show_basic_free_group == 1) ? item.isEdit == 1 ?
                                        <div className="displayInlineBlock free-days-edit editClass editLeftMargin" >
                                            {/* <BasicLabel text="Free Days" /> */}

                                            <BasicTextField
                                                label="Basic Free"
                                                value={item?.basic_free_period}
                                                onChange={(e) => {
                                                    this.onContractChange(index, "basic_free_period", e.target.value?.length > 0 ? e.target.value : null)
                                                    // this.onContractChange(index, "basic_free_type", e.target.value === "" ? null : item?.guarentee_period_type )
                                                }}
                                            />
                                            <SelectField
                                                id={"basic_free_type" + index}
                                                className="basic-free-select-field"
                                                value={item?.basic_free_type || item?.guarentee_period_type}
                                                options={this.state.basicFreeOptions}
                                                onChange={(e) => this.onContractChange(index, "basic_free_type", e.target.value)}
                                            />


                                        </div>
                                        // <div className="basic-free-container">
                                        //     <BasicLabel text="Free Days" />
                                        //     <div className="d-flex">
                                        //         <div className="basic-free-text-field">
                                        //             <BasicTextField
                                        //                 // label="Basic Free"
                                        //                 value={item?.basic_free_period}
                                        //                 onChange={(e) => {
                                        //                     this.onContractChange(index, "basic_free_period", e.target.value === "" ? null : e.target.value)
                                        //                     // this.onContractChange(index, "basic_free_type", e.target.value === "" ? null : item?.guarentee_period_type )
                                        //                 }}
                                        //             />
                                        //         </div>
                                        //         <div className="basic-free-select-field-col">
                                        //             {/* {item?.basic_free_type || item?.guarentee_period_type } */}
                                        //             <SelectField
                                        //                 className="basic-free-select-field"
                                        //                 value={item?.basic_free_type || item?.guarentee_period_type}
                                        //                 options={this.state.basicFreeOptions}
                                        //                 onChange={(e) => this.onContractChange(index, "basic_free_type", e.target.value)}
                                        //             />
                                        //         </div>
                                        //     </div>
                                        // </div> 
                                        :
                                        <div className="widthAuto readMarginLeft free-days-read readClass">
                                            <b className="paddingLeft0">Free Days</b>
                                            {item?.basic_free_period ? <div>{item?.basic_free_period}{item?.basic_free_type}</div> : <div className="noValue">--</div>}
                                        </div> : null
                                    }
                                    {(item?.show_sent_date == 1) ? item.isEdit == 1 ?
                                        <div className="displayInlineBlock sent-edit editClass editLeftMargin">
                                            <DateField
                                                id={"sent_date" + index}
                                                label="Sent"
                                                value={item?.sent_date}
                                                onChange={(e) => this.onContractChange(index, "sent_date", e.target.value?.length > 0 ? e.target.value : null)}
                                            />
                                        </div>
                                        // <div className="sent-date-col">
                                        //     <DateField
                                        //         label="Sent"
                                        //         value={item?.sent_date}
                                        //         onChange={(e) => this.onContractChange(index, "sent_date", e.target.value)}
                                        //     />
                                        // </div> 
                                        :
                                        <div className="widthAuto readMarginLeft sent-read readClass">
                                            <b className="paddingLeft0">Sent</b>
                                            {item?.sent_date ? <div>{getFormattedDate(item?.sent_date)}</div> : <div className="noValue">--</div>}
                                        </div> : null
                                    }
                                    {(item?.show_returned_date == 1) ? item.isEdit == 1 ?
                                        <div className="displayInlineBlock returned-edit editClass editLeftMargin">
                                            <DateField
                                                id={"returned_date" + index}
                                                label="Returned"
                                                value={item?.returned_date}
                                                onChange={(e) => this.onContractChange(index, "returned_date", e.target.value?.length > 0 ? e.target.value : null)}
                                            />
                                        </div>
                                        // <div className="returned-date-col">
                                        //     <DateField
                                        //         label="Returned"
                                        //         value={item?.returned_date}
                                        //         onChange={(e) => this.onContractChange(index, "returned_date", e.target.value)}
                                        //     />
                                        // </div> 
                                        :
                                        <div className="widthAuto readMarginLeft returned-read readClass">
                                            <b className="paddingLeft0">Returned</b>
                                            {item?.returned_date ? <div>{getFormattedDate(item?.returned_date)}</div> : <div className="noValue">--</div>}
                                        </div> : null
                                    }
                                    {(item?.show_revised_date == 1) ? item.isEdit == 1 ?
                                        <div className="displayInlineBlock revised-edit editClass editLeftMargin">
                                            <DateField tabIndex={0}
                                                id={"revised_date" + index}
                                                label="Revised"
                                                value={item?.revised_date}
                                                onChange={(e) => this.onContractChange(index, "revised_date", e.target.value?.length > 0 ? e.target.value : null)}
                                            />
                                        </div>
                                        // <div className="revised-date-col">
                                        //     <DateField
                                        //         label="Revised"
                                        //         value={item?.revised_date}
                                        //         onChange={(e) => this.onContractChange(index, "revised_date", e.target.value)}
                                        //     />
                                        // </div> 
                                        :
                                        <div className="widthAuto readMarginLeft revised-read readClass">
                                            <b className="paddingLeft0">Revised</b>
                                            {item?.revised_date ? <div>{getFormattedDate(item?.revised_date)}</div> : <div className="noValue">--</div>}
                                        </div> : null
                                    }
                                    {
                                        (item?.show_info) ?
                                            <div className={(item?.isEdit == 1) ? "contracts-icons info-edit editClass widthAuto editLeftMargin" : "contracts-icons info-read readClass readMarginLeft widthAuto"}>
                                                {(item?.isEdit == 1) ? <BasicLabel text="Info" /> : <b>Info</b>}
                                                {item?.info ? <div> <MDBTooltip tabIndex={item?.isEdit ? 0 : -1} wrapperProps={item?.isEdit ? { color: 'secondary', tabIndex: 0 } : { color: 'secondary' }} title={ReactHtmlParser(item?.info)}><span><MDBIcon id="info-button" className={item?.info ? item?.isEdit == 1 ? "green-icon edit" : "green-icon read" : ""} fas icon="info" onClick={() => item.isEdit == 1 ? this.showInfoPopover("info") : null} /></span></MDBTooltip></div> :
                                                    <div tabIndex={item?.isEdit ? 0 : -1}><MDBIcon id="info-button" className={item?.info ? item?.isEdit == 1 ? "green-icon edit" : "green-icon read" : ""} fas icon="info" onClick={() => item.isEdit == 1 ? this.showInfoPopover("info") : null} /></div>}

                                            </div>
                                            : null
                                    }
                                    {
                                        (item?.show_text) ?
                                            <div className={(item?.isEdit == 1) ? "contracts-icons info-edit editClass editLeftMargin" : "contracts-icons info-read readClass readMarginLeft"}>
                                                {(item?.isEdit == 1) ? <BasicLabel text="Text" /> : <b>Text</b>}
                                                {item?.text ? <div> <MDBTooltip tabIndex={item?.isEdit ? 0 : -1} wrapperProps={item?.isEdit ? { color: 'secondary', tabIndex: 0 } : { color: 'secondary' }} title={ReactHtmlParser(item?.text)}><span><MDBIcon id="file-contract-button" className={item?.text ? item?.isEdit == 1 ? "green-icon edit" : "green-icon read" : ""} fas icon="file-contract" onClick={() => item?.isEdit == 1 ? this.showFileContractPopover("text") : null} /></span></MDBTooltip></div> :
                                                    <div tabIndex={item?.isEdit ? 0 : -1}><MDBIcon id="file-contract-button" className={item?.text ? item?.isEdit == 1 ? "green-icon edit" : "green-icon read" : ""} fas icon="file-contract" onClick={() => item.isEdit == 1 ? this.showFileContractPopover("text") : null} /></div>}

                                            </div>
                                            : null
                                    }
                                    <div className={item?.isEdit ? "Total-amount-void editClass paddingLeft0 displayInlineBlock editLeftMargin" : "widthAuto readMarginLeft readClass"}>
                                        <div>
                                            <div className="total">{item?.isEdit ? <BasicLabel text="Total Amount" /> : <b className="paddingLeft0">Total Amount</b>}
                                            </div>
                                            <div className="totalAmountContainer" tabIndex={item?.isEdit ? 0 : -1}>{item?.currency_code} {item?.void ? "0.00" : item?.guarentee_period && item?.fee_type != "Schedule F - Daily" && item?.fee_type != "Schedule F - Weekly" ? formatRate(item.rate * item?.guarentee_period) : formatRate(item.rate * 1)}</div>
                                        </div>
                                        <div className="checkboxWrapper" tabIndex={item?.show_void && item?.isEdit ? 0 : -1}>
                                            {item?.show_void && item?.isEdit ?
                                                <BasicCheckbox
                                                    id={`void_${index}`}
                                                    label="Void"
                                                    checked={item?.void}
                                                    onChange={(e) => this.handleCheck(e, index)}
                                                /> : null}
                                        </div>
                                        {/* <div className="void">
                                                {item?.show_void && item?.isEdit ?
                                                    <BasicCheckbox
                                                        id={`void_${index}`}
                                                        label="Void"
                                                        checked={item?.void}
                                                        onChange={(e) => this.handleCheck(e, index)}
                                                    /> : null}
                                            </div> */}
                                    </div>
                                </div>
                                <div className={item?.isEdit == 1 ? "contract-edit-container editClass" : "contract-edit-container readClass"}>
                                    {!this.props?.summary ?
                                        <>
                                            <div>
                                            {this.props?.userContext?.user_profile?.user_role_name !== "Read Only" &&
                                                <MDBIcon fas icon={item?.isEdit ? "save" : "pen"} onClick={() => item?.isEdit ? this.validateBeforeSave(index) : this.editContract(index)} />}
                                                </div>
                                            <div>
                                            {this.props?.userContext?.user_profile?.user_role_name !== "Read Only" &&
                                                <MDBIcon fas icon={item?.isEdit ? "times" : "minus"} onClick={() => item?.isEdit ? this.unsaveContract(index) : this.deleteContract(index)} />}
                                                </div>
                                        </> : null}
                                </div>
                            </div>
                        </div>
                    )) : (this.props?.summary ? "No Compensation is entered" : null)) : <CircularProgress color="inherit" size={20} />}
                    <br />
                </MDBCol>
                {
                    this.state.openEsignModal ?
                        <Modal
                            open={this.state.openEsignModal}
                            modalName={"Esign Modal"}
                            headerText={"E-Sign Manager"}
                            mode="Both"
                            handleClose={() => this.closeEsignManager()}
                            performer_deal_contract_id={this.state.performer_deal_contract_id}
                            performer_deal_id={this.props?.performer_deal_id}
                            loanout_present={this.props?.loanout_present}
                            contract_info={this.state.contract_info}
                            project_id={this.props?.project_id}
                            getCompensationTabData={() => this.getCompensationTabData()}
                            boxURL={this.props?.boxURL}
                            file_name={this.state.file_name}
                            monthsAge={this.props?.monthsAge}
                            age={this.props?.age}
                            birth_date={this.props?.birth_date}
                        /> : null
                }
                {
                    this.state.openEditRider &&
                    <Modal
                        open={this.state.openEditRider}
                        modalName={"Edit Riders"}
                        headerText={this.state.rider_type === "From Memos" ? "Edit Rider" : "Edit Exhibit A Rider"}
                        mode="Both"
                        handleClose={() => this.closeEditRiderModal()}
                        boxURL={this.props?.boxURL}
                        contract_type={this.props?.contract_type}
                        project_id={this.props?.project_id}
                        performer_deal_id={this.props?.performer_deal_id}
                        performer_deal_contract_id={this.state.performer_deal_contract_id}
                        loanout_present={this.props?.loanout_present}
                        contract_info={this.state.contract_info}
                        rider_type={this.state.rider_type}
                        deal_contract={this.state.dealContracts?.filter(i => i?.is_delete == 0).filter(i => i?.performer_deal_contract_id == this.state?.performer_deal_contract_id)}
                        rider_info={this.state.contract_info.riders?.filter(item => item?.rider_type === this.state.rider_type)}
                        getCompensationTabData={() => this.getCompensationTabData()}
                    />
                }
                {
                    (this.state.openInfoPopover || this.state.openFileContractPopover) && (
                        <ModalWithBackdrop
                            open={this.state.openInfoPopover || this.state.openFileContractPopover}
                            handleClose={(this.state.tooltipBtnPressed === "info" && this.hideInfoPopover) || (this.state.tooltipBtnPressed === "text" && this.hideFileContractPopover)}
                            title={(this.state.tooltipBtnPressed === "info" && "Edit Info") || (this.state.tooltipBtnPressed === "text" && "Edit Text")}
                            mode={"Both"}
                            modalName={"Edit Info and text"}
                            infoTextValue={(this.state.tooltipBtnPressed === "info" && this.state.dealContracts[globalIndex]?.info) || (this.state.tooltipBtnPressed === "text" && this.state.dealContracts[globalIndex]?.text)}
                            dealContracts={this.state.dealContracts}
                            globalIndex={globalIndex}
                            tooltipBtnPressed={this.state.tooltipBtnPressed}
                            identifier={this.state.tooltipBtnPressed}
                            handleOnsaveRTE={this.handleOnsaveRTE.bind(this)}
                        />
                    )
                }
                {
                    (this.state.openNewInfoPopover || this.state.openNewContractPopover) && (
                        <ModalWithBackdrop
                            open={this.state.openNewInfoPopover || this.state.openNewContractPopover}
                            handleClose={(this.state.tooltipBtnPressed === "newInfo" && this.hideNewInfoPopover) || (this.state.tooltipBtnPressed === "newText" && this.hideNewFileContractPopover)}
                            title={this.state.tooltipBtnPressed === "newInfo" && "New Info" || this.state.tooltipBtnPressed === "newText" && "New Text"}
                            mode={"Both"}
                            modalName={"Add Info and text"}
                            infoTextValue={(this.state.tooltipBtnPressed === "newInfo" && this.state.newContract?.info) || (this.state.tooltipBtnPressed === "newText" && this.state.newContract?.text)}
                            newContract={this.state.newContract}
                            tooltipBtnPressed={(this.state.tooltipBtnPressed === "newInfo" && "info") || (this.state.tooltipBtnPressed === "newText" && "text")}
                            identifier={this.state.tooltipBtnPressed}
                            handleOnsaveRTE={this.handleOnsaveRTE.bind(this)}
                        />
                    )
                }

            </div >
        )
    }
}