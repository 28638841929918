import React from 'react';
import TableComponent from '../SharedComponents/Table';
import CasterService from '../../../../src/services/service';
import * as Constants from '../../../constants/constants';
import MainViewModalComponent from '../../Common/MainViewModalLayout/Modal/Modal';
import NotificationComponent from '../../Common/NotificationComponent/NotificationComponent'
import {StatusDatesConfig} from'./config'
import { getFormattedDate } from '../../../caster-feature-old/Common/Helper';
import MessageModal from '../SharedComponents/MessageModal';
import './StatusDates.scss';
import { withUserContext } from '../../../contexts/UserContext';
import {updateTableSortConfigObject, tableSortList } from '../../Common/TableHelper';


class StatusDates extends React.Component {
  constructor(props) {
    super(props);
    const initialconfig = JSON.parse(JSON.stringify(StatusDatesConfig));
    this.state = {  
      allStatusDatesData:[],
     // config: JSON.parse(JSON.stringify({ ...StatusDatesConfig})),
     config : this.modifyConfigBasedOnRole(initialconfig),  
      isFetchingStatusDatesData:false,
      openNotification:null,
      severity:null,
      isDelete:null,
      deleteItem:null,
      editStatusDatesData:null,
      isSortByAsc: true,
      sortCount: 0,
      sortBy: "From",
    }
  }

  modifyConfigBasedOnRole = (config) => {
    const isReadOnlyUser = this.props?.userContext?.user_profile?.user_role_name !=="Admin";
    if (isReadOnlyUser){
      config.actions = [];
    }
    return config;
    
  }


  componentDidMount() {
     this.getStatusDatesData(true)
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps?.reloadStatusDates !== this.props?.reloadStatusDates) {
      this.getStatusDatesData(true)
    }
  }

  getStatusDatesData(loader=false) {
    this.setState({isFetchingStatusDatesData:loader})
    CasterService.getData(Constants.CasterServiceBaseUrl + `/casterFeatureProjectStatusDates?project_id=${this.props?.project_id}`).then((response) => {
      if(response?.data?.error) {
         this.setState({openNotification: 'Status Dates Data Load Fail', severity:'error'})
      }
      else {
          let formattedList = response?.data?.length > 0 ? response?.data?.map(item => {
            let newItem = {...item}
            newItem.from_date = item?.from_date ? getFormattedDate(item?.from_date) : null
            newItem.to_date = item?.to_date ? getFormattedDate(item?.to_date) : null
            return newItem
          }): []
          let configData = JSON.parse(JSON.stringify(this.state.config));
          configData = this.modifyConfigBasedOnRole(configData);
          this.setState({isSortByAsc: true,
            sortCount: 0,
            config: configData,
            //config: JSON.parse(JSON.stringify({ ...StatusDatesConfig})),
            sortBy: "From",allStatusDatesData: formattedList, isFetchingStatusDatesData:false, isDelete:null})
      }
  },
      (err) => {this.setState({openNotification: 'Status Dates Data Load Fail', severity:'error'})
      })
  }


 deleteStatusDates  = (deleteData) => {
  this.setState({isDelete : deleteData?.project_status_dates_id, deleteItem : null })
  CasterService.postDataParams(Constants.CasterServiceBaseUrl + `/casterFeatureProjectStatusDates`, deleteData)
  .then(response => {
      if(response?.data?.error) {
        this.setState({openNotification: response?.data?.message || 'Deletion Failed', severity:'error', isSubmit:null})
      } else {
        this.setState({openNotification: 'Deleted successfully', severity:'success'})
        this.getStatusDatesData()
      }
  },
  (err) => {
    this.setState({openNotification: 'Saving changes failed', severity:'error', isSubmit:null})
  }
  )
 }

 handleStatusDatesDelete = (val, item) => {
    this.setState({deleteItem: item})
 }

 editCallback = (id,dataItem) => {
    this.setState({editStatusDatesData:dataItem})
 }

 handleTableColumnClick = (column) => {
  if (column === this.state.sortBy) {
    this.setState({ sortCount: this.state.sortCount + 1 }, () => {
      this.handleTableSort(column);
    })
  }
  else this.setState({ sortCount: 0 }, () => {
    this.handleTableSort(column);
  })
}

getSortNode = (col) => {
  switch (col) {
    case "From": return "from_date";
    case "To": return "to_date";
    case "Status": return "status";
    case "Notes": return "notes";
  }
}

handleTableSort = (colName) => {
  let coltype = (colName === 'From' || colName === 'To') ? 'Date' :  'Name';
  this.setState({
    isSortByAsc: this.state.sortCount % 2 === 0 ? true : false,
  }, () => {
    this.setState({
      sortBy: colName,
      config: updateTableSortConfigObject(this.state.config, this.state.isSortByAsc, this.state.sortBy, colName),
      allStatusDatesData: tableSortList(coltype, this.getSortNode(colName), this.state.allStatusDatesData, this.state.isSortByAsc)
    });
  })
}

  render() {
    return (
        <div className='casterFeatureStatusDatesSection'>
          <MessageModal
            open={this.state.deleteItem ? true : false}
            title={'Delete Status Date'}
            message={'Are you sure to delete this status date?, please click OK to confirm'}
            hideCancel={false}
            primaryButtonText={"OK"}
            secondaryButtonText={"Cancel"}
            handleClose={() => this.setState({deleteItem:null})}
            onConfirm={(e) => {
                let deleteData = {...this.state.deleteItem}
                deleteData.is_delete = '1'
                this.deleteStatusDates(deleteData)
            }}/>
          <NotificationComponent 
            open={this.state.openNotification ? true : false} 
            message={this.state.openNotification || ""}
            severity={this.state.severity || ""}
            handleResetNotify={() => this.setState({openNotification:null,severity:null})} />
          <TableComponent 
            id={'feactureCastingStatusDatesSection'}
            list ={this.state.allStatusDatesData}
            config={this.state.config}
            isLoading={this.state.isFetchingStatusDatesData}
            postInitiated={this.state.isDelete}
            sortCallback={this.handleTableColumnClick}
            handleDelCallBack = {(val, item) => this.handleStatusDatesDelete(val, item)}
            editCallback={this.editCallback.bind(this)}
          />
          {this.state?.editStatusDatesData &&
          <MainViewModalComponent
            modalName='Status Date'
            handleClose={() => { this.setState({ editStatusDatesData: null }) }}
            mode={'edit'}
            open={this.state.editStatusDatesData ? true : false}
            headerText={"Status Date"}
            statusDate={this.state.editStatusDatesData}
            toggleRefreshPage={this.getStatusDatesData.bind(this)}
          />}
        </div>
    )
  }
}

export default withUserContext(StatusDates);
